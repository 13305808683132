// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import ApiIntegrationWithSfSuccessfactors3 from "../../blocks/ApiIntegrationWithSfSuccessfactors3/src/ApiIntegrationWithSfSuccessfactors3";
import Analytics from "../../blocks/analytics/src/Analytics";
import CalendarSyncColorCoding from "../../blocks/CalendarSyncColorCoding/src/CalendarSyncColorCoding";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import LeaveTracker from "../../blocks/LeaveTracker/src/LeaveTracker";
import TimesheetManagement from "../../blocks/TimesheetManagement/src/TimesheetManagement";



const routeMap = {
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
ApiIntegrationWithSfSuccessfactors3:{
 component:ApiIntegrationWithSfSuccessfactors3,
path:"/ApiIntegrationWithSfSuccessfactors3"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
CalendarSyncColorCoding:{
 component:CalendarSyncColorCoding,
path:"/CalendarSyncColorCoding"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
LeaveTracker:{
 component:LeaveTracker,
path:"/LeaveTracker"},
TimesheetManagement:{
 component:TimesheetManagement,
path:"/TimesheetManagement"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;